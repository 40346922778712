<template>
  <section id="conversion-rate">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <v-select v-model="selectedCountry" label="title" placeholder="Country" :options="allCountries"
            @input="changeSelectedCountry" />
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col xl="6" md="6">
          <v-select v-model="selectedContactPerson" label="title" placeholder="Contact Person"
            :options="allContactPersons" @input="changeSelectedContactPerson" />
        </b-col>
        <b-col xl="6" md="6">
          <b-form-radio-group button-variant="primary" buttons class="btn-block" name="radios-btn-default"
            v-model="selectedCountryGroup" :options="allCountryGroups" @change="changeCountryGroup" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="6">
          <b-row>
            <b-col xl="12" md="12">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ avgConversionRate | formatNumber }}
                    </h2>
                    <span>Avg. conversion rate</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col xl="12" md="12">
              <b-card no-body>
                <b-card-header>
                  <h4 class="mb-0">
                    Conversion rate
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                      id="popover-conversion-rate" />
                  </h4>
                  <b-popover target="popover-conversion-rate" triggers="hover" placement="bottom">
                    <span>The 'Conversion Rate' section displays a line chart showing the conversion rate, separated by
                      month and year. This visual representation helps track and analyze the conversion rate trends over
                      time.</span>
                  </b-popover>
                </b-card-header>
                <ECharts ref="conversionRateChart" :options="conversionRateChart" style="width: 100%; height: 400px;" />
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="6">
          <b-row>
            <b-col xl="6">
              <b-card no-body>
                <b-card-header>
                  <h4 class="mb-0">
                    Top countries
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                      id="popover-top-countries" />
                  </h4>
                  <b-popover target="popover-top-countries" triggers="hover" placement="bottom">
                    <span>Top countries table</span>
                  </b-popover>
                </b-card-header>
                <b-table striped hover responsive class="position-relative"
                  :current-page="topCountriesTable.currentPage" :per-page="10" :items="topCountriesTable.items"
                  :fields="topCountriesTable.fields" :sort-by.sync="topCountriesTable.sortBy"
                  :sort-desc.sync="topCountriesTable.sortDesc" :sort-direction="topCountriesTable.sortDirection"
                  :filter="topCountriesTable.filter" :filter-included-fields="topCountriesTable.filterOn" />
              </b-card>
            </b-col>
            <b-col xl="6">
              <b-card no-body>
                <b-card-header>
                  <h4 class="mb-0">
                    Flop countries
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                      id="popover-flop-countries" />
                  </h4>
                  <b-popover target="popover-flop-countries" triggers="hover" placement="bottom">
                    <span>Flop countries table</span>
                  </b-popover>
                </b-card-header>
                <b-table striped hover responsive class="position-relative"
                  :current-page="flopCountriesTable.currentPage" :per-page="10" :items="flopCountriesTable.items"
                  :fields="flopCountriesTable.fields" :sort-by.sync="flopCountriesTable.sortBy"
                  :sort-desc.sync="flopCountriesTable.sortDesc" :sort-direction="flopCountriesTable.sortDirection"
                  :filter="flopCountriesTable.filter" :filter-included-fields="flopCountriesTable.filterOn" />
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders/offers count
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-orders-offers-count" />
              </h4>
              <b-popover target="popover-orders-offers-count" triggers="hover" placement="bottom">
                <span>The 'Orders/Offers Count' section displays a dual line chart, with one line representing the
                  orders count and the other representing the offers count, separated by month and year. This allows for
                  easy comparison and analysis of orders and offers over time.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersOffersCountChart" :options="ordersOffersCountChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Orders/offers amount
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-orders-offers-amount" />
              </h4>
              <b-popover target="popover-orders-offers-amount" triggers="hover" placement="bottom">
                <span>The 'Orders/Offers Amount' section displays a dual line chart showing the amounts for orders and
                  offers, separated by month and year. This visual representation helps analyze financial trends and
                  performance over time.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="ordersOffersAmountChart" :options="ordersOffersAmountChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-overlay :show="show2" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Conversion rate by country group
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-conversion-rate-country-group" />
              </h4>
              <b-popover target="popover-conversion-rate-country-group" triggers="hover" placement="bottom">
                <span>
                  The 'Conversion Rate by Country Group' section displays a bar chart showing the conversion rate for
                  different groups: 'Germany,' 'EU (except Germany),' and 'Outside EU' The data is separated by month
                  and year, providing a detailed view of conversion rates across regions over time.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="conversionRateByCountryGroupChart" :options="conversionRateByCountryGroupChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Conversion rate by value group
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-conversion-rate-value-group" />
              </h4>
              <b-popover target="popover-conversion-rate-value-group" triggers="hover" placement="bottom">
                <span>The 'Conversion Rate by Value Group' section displays a bar chart with value groups ('lower
                  1000€', '1000-5000€', 'more 5000€'), separated by month and year. This chart provides insights into
                  conversion rates across different order value ranges over time.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="conversionRateByValueGroupChart" :options="conversionRateByValueGroupChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Conversion rate by country group by quarter
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-conversion-rate-country-group-quarter" />
              </h4>
              <b-popover target="popover-conversion-rate-country-group-quarter" triggers="hover" placement="bottom">
                <span>The 'Conversion Rate by Country Group by Quarter' section displays bar charts for different
                  groups: 'Germany,' 'EU (except Germany),' and 'Outside EU,' separated by quarter and year. This allows
                  for a detailed analysis of conversion rates across regions on a quarterly basis.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="conversionRateByCountryGroupAndQuarterChart"
              :options="conversionRateByCountryGroupAndQuarterChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Conversion rate by country group by year
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-conversion-rate-country-group-year" />
              </h4>
              <b-popover target="popover-conversion-rate-country-group-year" triggers="hover" placement="bottom">
                <span>The 'Conversion Rate by Country Group by Year' section displays bar charts for different groups:
                  'Germany,' 'EU (except Germany),' and 'Outside EU' separated by year. This provides a clear annual
                  comparison of conversion rates across different regions.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="conversionRateByCountryGroupAndYearChart" :options="conversionRateByCountryGroupAndYearChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BTable,
  BRow,
  BCol,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadioGroup,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';
import 'echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BTable,
    BRow,
    BCol,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    vSelect,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      show: true,
      show2: true,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      selectedCountry: [],
      allCountries: [],
      selectedCountryGroup: 2,
      allCountryGroups: [
        { text: "All", value: 2 },
        { text: "EU (except Germany)", value: 1 },
        { text: "Outside EU", value: 0 },
      ],
      selectedContactPerson: '',
      allContactPersons: [],
      avgConversionRate: '',
      queryParams: {},
      queryParams2: {},
      ordersOffersCountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      ordersOffersAmountChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      conversionRateChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      conversionRateByCountryGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      conversionRateByCountryGroupAndQuarterChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      conversionRateByCountryGroupAndYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      conversionRateByValueGroupChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      topCountriesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'conversion_rate',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'country', label: 'country', sortable: true },
          { key: 'offers_count', label: 'offers', sortable: true },
          { key: 'orders_count', label: 'orders', sortable: true },
          {
            key: 'conversion_rate',
            label: 'conversion rate',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      flopCountriesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'conversion_rate',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'country', label: 'country', sortable: true },
          { key: 'offers_count', label: 'offers count', sortable: true },
          { key: 'orders_count', label: 'orders count', sortable: true },
          {
            key: 'conversion_rate',
            label: 'conversion rate',
            sortable: true,
            formatter: val => `${this.$formatNumber(val)}`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];
    this.queryParams2.from_date = dateRange[0];
    this.queryParams2.to_date = dateRange[1];

    try {
      await this.getAllCountries();
      await this.getContactPersons();
      await this.getConversionRate();
      await this.getConversionRateByGroups();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllCountries() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-countries/`, {});
        this.allCountries = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getContactPersons() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/jtl-support-employee-names/`, this.queryParams);
        this.allContactPersons = response.data.results.map(item => item.full_name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    updateCharts(data) {
      const conversionRateValues = data.data.results.map(item => item.conversion_rate);
      this.ordersOffersCountChart.xAxis.data = data.data.results.map(item => item.month_year);
      this.ordersOffersCountChart.series = [
        {
          name: "Orders",
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top',
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: data.data.results.map(item => item.orders_count),
        },
        {
          name: "Offers",
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top',
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: data.data.results.map(item => item.offers_count),
        },
      ];
      this.ordersOffersAmountChart.xAxis.data = data.data.results.map(item => item.month_year);
      this.ordersOffersAmountChart.series = [
        {
          name: "Orders",
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top',
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: data.data.results.map(item => item.orders_value),
        },
        {
          name: "Offers",
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top',
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          },
          data: data.data.results.map(item => item.offers_value),
        },
      ];
      this.conversionRateChart.xAxis.data = data.data.results.map(item => item.month_year);
      this.conversionRateChart.series = {
        name: "Conversion rate",
        type: 'line',
        label: {
          show: true,
          position: 'top',
          distance: 5,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
        },
        data: conversionRateValues,
      };
      this.avgConversionRate = (conversionRateValues.reduce((a, b) => (a + b)) / conversionRateValues.length);
    },
    updateCharts2(data) {
      const dataConversionRateByCountryGroup = data[0].data.results;
      const dataConversionRateByValueGroup = data[1].data.results;
      const dataConversionRateByCountryGroupAndQuarter = data[3].data.results;
      const dataConversionRateByCountryGroupAndYear = data[4].data.results;

      let countryGroups = dataConversionRateByCountryGroup.map(item => item.country_group);
      let monthsYears = dataConversionRateByCountryGroup.map(item => item.month_year);
      let quartersYears = dataConversionRateByCountryGroupAndQuarter.map(item => item.quarter_year);
      let years = dataConversionRateByCountryGroupAndYear.map(item => item.year);
      countryGroups = [...new Set(countryGroups)];
      monthsYears = [...new Set(monthsYears)];
      quartersYears = [...new Set(quartersYears)];
      years = [...new Set(years)];

      let conversionRateByCountryGroupChartDatasets = [];
      let conversionRateByCountryGroupAndQuarterChartDatasets = [];
      let conversionRateByCountryGroupAndYearChartDatasets = [];
      let findValues;
      for (var i = 0; i < countryGroups.length; i++) {
        let conversionRateByCountryGroup = [];
        let cuntryGroupOffersOrdersCount = [];
        let conversionRateByCountryGroupAndYear = [];
        let cuntryGroupByYearOffersOrdersCount = [];
        let conversionRateByCountryGroupAndQuarter = [];
        let cuntryGroupByQuarterOffersOrdersCount = [];

        for (var j = 0; j < monthsYears.length; j++) {
          findValues = false;
          for (var k = 0; k < dataConversionRateByCountryGroup.length; k++) {
            if (countryGroups[i] === dataConversionRateByCountryGroup[k].country_group && monthsYears[j] === dataConversionRateByCountryGroup[k].month_year) {
              conversionRateByCountryGroup.push(dataConversionRateByCountryGroup[k].conversion_rate);
              cuntryGroupOffersOrdersCount.push(dataConversionRateByCountryGroup[k].offers_count + '/' + dataConversionRateByCountryGroup[k].orders_count);
              findValues = true;
              dataConversionRateByCountryGroup.splice(k, 1);
              break;
            }
          }
          if (!findValues) {
            conversionRateByCountryGroup.push(0.0);
            cuntryGroupOffersOrdersCount.push(0.0);
          }
        }

        for (var j = 0; j < quartersYears.length; j++) {
          findValues = false;
          for (var k = 0; k < dataConversionRateByCountryGroupAndQuarter.length; k++) {
            if (countryGroups[i] === dataConversionRateByCountryGroupAndQuarter[k].country_group && quartersYears[j] === dataConversionRateByCountryGroupAndQuarter[k].quarter_year) {
              conversionRateByCountryGroupAndQuarter.push(dataConversionRateByCountryGroupAndQuarter[k].conversion_rate);
              cuntryGroupByQuarterOffersOrdersCount.push(dataConversionRateByCountryGroupAndQuarter[k].offers_count + '/' + dataConversionRateByCountryGroupAndQuarter[k].orders_count);
              findValues = true;
              dataConversionRateByCountryGroupAndQuarter.splice(k, 1);
              break;
            }
          }
          if (!findValues) {
            conversionRateByCountryGroupAndQuarter.push(0.0);
            cuntryGroupByQuarterOffersOrdersCount.push(0.0);
          }
        }

        for (var j = 0; j < years.length; j++) {
          findValues = false;
          for (var k = 0; k < dataConversionRateByCountryGroupAndYear.length; k++) {
            if (countryGroups[i] === dataConversionRateByCountryGroupAndYear[k].country_group && years[j] === dataConversionRateByCountryGroupAndYear[k].year) {
              conversionRateByCountryGroupAndYear.push(dataConversionRateByCountryGroupAndYear[k].conversion_rate);
              cuntryGroupByYearOffersOrdersCount.push(dataConversionRateByCountryGroupAndYear[k].offers_count + '/' + dataConversionRateByCountryGroupAndYear[k].orders_count);
              findValues = true;
              dataConversionRateByCountryGroupAndYear.splice(k, 1);
              break;
            }
          }
          if (!findValues) {
            conversionRateByCountryGroupAndYear.push(0.0);
            cuntryGroupByYearOffersOrdersCount.push(0.0);
          }
        }

        conversionRateByCountryGroupChartDatasets.push({
          name: countryGroups[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: conversionRateByCountryGroup,
        });
        conversionRateByCountryGroupAndQuarterChartDatasets.push({
          name: countryGroups[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: conversionRateByCountryGroupAndQuarter,
        });
        conversionRateByCountryGroupAndYearChartDatasets.push({
          name: countryGroups[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: conversionRateByCountryGroupAndYear,
        });
      }

      this.conversionRateByCountryGroupChart.xAxis.data = monthsYears;
      this.conversionRateByCountryGroupChart.series = conversionRateByCountryGroupChartDatasets;

      this.conversionRateByCountryGroupAndQuarterChart.xAxis.data = quartersYears;
      this.conversionRateByCountryGroupAndQuarterChart.series = conversionRateByCountryGroupAndQuarterChartDatasets;

      this.conversionRateByCountryGroupAndYearChart.xAxis.data = years;
      this.conversionRateByCountryGroupAndYearChart.series = conversionRateByCountryGroupAndYearChartDatasets;

      // ---
      monthsYears = dataConversionRateByValueGroup.map(item => item.month_year);
      let valueGroups = ['<1000€', '1000-5000€', '>5000€'];
      monthsYears = [...new Set(monthsYears)];

      let conversionRateByValueGroupChartDatasets = [];
      for (var i = 0; i < valueGroups.length; i++) {
        let conversionRateByValueGroup = [];
        let valueGroupOffersOrdersCount = [];

        for (var j = 0; j < monthsYears.length; j++) {
          findValues = false;
          for (var k = 0; k < dataConversionRateByValueGroup.length; k++) {
            if (valueGroups[i] === dataConversionRateByValueGroup[k].value_group && monthsYears[j] === dataConversionRateByValueGroup[k].month_year) {
              conversionRateByValueGroup.push(dataConversionRateByValueGroup[k].conversion_rate);
              valueGroupOffersOrdersCount.push(dataConversionRateByValueGroup[k].offers_count + '/' + dataConversionRateByValueGroup[k].orders_count);
              findValues = true;
              dataConversionRateByValueGroup.splice(k, 1);
              break;
            }
          }
          if (!findValues) {
            conversionRateByValueGroup.push(0.0);
            valueGroupOffersOrdersCount.push(0.0);
          }
        }

        conversionRateByValueGroupChartDatasets.push({
          name: valueGroups[i],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: conversionRateByValueGroup,
        });
      }

      this.conversionRateByValueGroupChart.xAxis.data = monthsYears;
      this.conversionRateByValueGroupChart.series = conversionRateByValueGroupChartDatasets;

      this.topCountriesTable.items = data[2].data.results;
      this.topCountriesTable.totalRows = data[2].data.results.length * 2;

      this.flopCountriesTable.items = data[2].data.results;
      this.flopCountriesTable.totalRows = data[2].data.results.length * 2;
    },
    async getConversionRate() {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate/`, this.queryParams);
        this.updateCharts(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async getConversionRateByGroups() {
      this.show2 = true;
      try {
        const currYear = new Date().getFullYear();
        const prevYear = currYear - 2;
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-country-group-and-month/`, this.queryParams2),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-value-group/`, this.queryParams2),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-country/`, this.queryParams2),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-country-group-and-quarter/`, { from_date: `${prevYear}-01-01`, to_date: `${currYear}-12-31` }),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/conversion-rate-by-country-group-and-year/`, { from_date: `${prevYear}-01-01`, to_date: `${currYear}-12-31` }),
        ]);
        this.updateCharts2(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show2 = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        this.queryParams2.from_date = dateRange[0];
        this.queryParams2.to_date = dateRange[1];
        await this.getConversionRate();
        await this.getConversionRateByGroups();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.conversionRateChart.refresh();
          this.$refs.ordersOffersCountChart.refresh();
          this.$refs.ordersOffersAmountChart.refresh();
          this.$refs.conversionRateByCountryGroupChart.refresh();
          this.$refs.conversionRateByValueGroupChart.refresh();
          this.$refs.conversionRateByCountryGroupAndQuarterChart.refresh();
          this.$refs.conversionRateByCountryGroupAndYearChart.refresh();
        });
      }
    },
    async changeSelectedCountry() {
      this.selectedCountryGroup = null;
      this.queryParams.country = this.selectedCountry;
      this.queryParams.eu = this.selectedCountryGroup;
      await this.getConversionRate();

      this.$nextTick(() => {
        this.$refs.conversionRateChart.refresh();
        this.$refs.ordersOffersCountChart.refresh();
        this.$refs.ordersOffersAmountChart.refresh();
      });
    },
    async changeCountryGroup() {
      this.selectedCountry = null;
      this.queryParams.country = this.selectedCountry;
      if (this.selectedCountryGroup == 2) {
        this.queryParams.eu = null;
      }
      else {
        this.queryParams.eu = this.selectedCountryGroup;
      }
      await this.getConversionRate();

      this.$nextTick(() => {
        this.$refs.conversionRateChart.refresh();
        this.$refs.ordersOffersCountChart.refresh();
        this.$refs.ordersOffersAmountChart.refresh();
      });
    },
    async changeSelectedContactPerson() {
      this.queryParams.employee = this.selectedContactPerson;
      this.queryParams2.employee = this.selectedContactPerson;
      await this.getConversionRate();
      await this.getConversionRateByGroups();

      this.$nextTick(() => {
        this.$refs.conversionRateChart.refresh();
        this.$refs.ordersOffersCountChart.refresh();
        this.$refs.ordersOffersAmountChart.refresh();
        this.$refs.conversionRateByCountryGroupChart.refresh();
        this.$refs.conversionRateByValueGroupChart.refresh();
        this.$refs.conversionRateByCountryGroupAndQuarterChart.refresh();
        this.$refs.conversionRateByCountryGroupAndYearChart.refresh();
      });
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
